import { environment } from '../../environments/environment';

import { MovimentacaoDocumento } from '../core/model';

import { Headers, URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';
import { AuthHttp } from 'angular2-jwt';

import { LazyLoadEvent } from "primeng/components/common/api";

import 'rxjs/add/operator/toPromise';
import * as moment from 'moment';
import { AuthService } from '../seguranca/auth.service';

export class MovimentacaoDocumentoFiltro {
  documentoChamamentoPublico: string;
  logMovimentacao: string;
  pagina = 0;
 itensPorPagina = 20;
}

@Injectable()
export class MovimentacaoDocumentoService {

  _url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this._url = `${environment.apiUrl}/movimentacao-documento-chamamento-publico`;
  }

  pesquisar(filtro: MovimentacaoDocumentoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.documentoChamamentoPublico) {
      params.set('documentoChamamentoPublico', filtro.documentoChamamentoPublico);
    }
    if (filtro.logMovimentacao) {
      params.set('logMovimentacao', filtro.logMovimentacao);
    }

    return this.http.get(`${this._url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        // //console.log(resultado)
        return resultado;

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this._url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this._url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: MovimentacaoDocumento): Promise<MovimentacaoDocumento> {
    return this.http.post(this._url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: MovimentacaoDocumento): Promise<MovimentacaoDocumento> {

    return this.http.put(`${this._url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as MovimentacaoDocumento);
  }

  buscarPorCodigo(codigo: number): Promise<MovimentacaoDocumento> {
    return this.http.get(`${this._url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as MovimentacaoDocumento;
        return entidade;
      });
  }

}
