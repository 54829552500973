import { URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { TipoAvaliacaoPlano } from '../../model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';

export class TipoAvaliacaoPlanoFiltro {
  tipo
  modelo

  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class TipoAvaliacaoPlanoService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/tipo-avaliacao-plano`;
  }

  pesquisar(filtro: TipoAvaliacaoPlanoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.tipo) {
      params.set('tipo', filtro.tipo);
    }
    if (filtro.modelo) {
      params.set('modelo', filtro.modelo);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        return resultado;

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: TipoAvaliacaoPlano): Promise<TipoAvaliacaoPlano> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: TipoAvaliacaoPlano): Promise<TipoAvaliacaoPlano> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as TipoAvaliacaoPlano);
  }

  buscarPorCodigo(codigo: number): Promise<TipoAvaliacaoPlano> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as TipoAvaliacaoPlano;
        return entidade;
      });
  }

}
