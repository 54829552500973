import { URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../seguranca/auth.service';
import { AlunoHasEscola } from '../../../model/escolar/alunoHasEscola.model';

export class AlunoHasEscolaFiltro {
    nome;
    escola;
    aluno;
    escolaExcluida;

    pagina = 0;
    itensPorPagina = 20;
}

@Injectable()
export class AlunoHasEscolaService {

    url: string;


    constructor(private http: AuthHttp,
        public auth: AuthService,) {
        this.url = `${environment.apiUrl}/aluno-has-escola`;
    }

    pesquisar(filtro: AlunoHasEscolaFiltro): Promise<any> {
        const params = new URLSearchParams();

        params.set('page', filtro.pagina.toString());
        params.set('size', filtro.itensPorPagina.toString());

        if (filtro.nome) {
            params.set('nome', filtro.nome);
        }

        if (filtro.escola) {
            params.set('escola', filtro.escola);
        }
        
        if (filtro.aluno) {
            params.set('aluno', filtro.aluno);
        }

        if (filtro.escolaExcluida) {
            params.set('escolaExcluida', filtro.escolaExcluida);
        }

        return this.http.get(`${this.url}`, { search: params })
            .toPromise()
            .then(response => {
                return response.json();
            })
    }

    listarTodos(): Promise<any> {
        return this.http.get(this.url)
            .toPromise()
            .then(response => response.json().content);
    }

    excluir(codigo: number): Promise<void> {
        return this.http.delete(`${this.url}/${codigo}`)
            .toPromise()
            .then(() => null);
    }

    adicionar(entidade: AlunoHasEscola): Promise<AlunoHasEscola> {
        return this.http.post(this.url, JSON.stringify(entidade))
            .toPromise()
            .then(response => response.json());
    }

    atualizar(entidade: AlunoHasEscola): Promise<AlunoHasEscola> {

        return this.http.put(`${this.url}/${entidade.id}`,
            JSON.stringify(entidade))
            .toPromise()
            .then(response => response.json() as AlunoHasEscola);
    }

    buscarPorCodigo(codigo: number): Promise<AlunoHasEscola> {
        return this.http.get(`${this.url}/${codigo}`)
            .toPromise()
            .then(response => {
                const entidade = response.json() as AlunoHasEscola;
                return entidade;
            });
    }

}
