import { URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../seguranca/auth.service';
import { PeriodoLetivo } from '../../../model/escolar/periodoLetivo.model';

export class PeriodoLetivoFiltro {
    identificacao
    secretaria
    ano
    status

    pagina = 0;
    itensPorPagina = 20;
}

@Injectable()
export class PeriodoLetivoService {

    url: string;


    constructor(private http: AuthHttp,
        public auth: AuthService,) {
        this.url = `${environment.apiUrl}/periodo-letivo`;
    }

    pesquisar(filtro: PeriodoLetivoFiltro): Promise<any> {
        const params = new URLSearchParams();

        params.set('page', filtro.pagina.toString());
        params.set('size', filtro.itensPorPagina.toString());

        if (filtro.identificacao) {
            params.set('identificacao', filtro.identificacao);
        }
        
        if (filtro.secretaria) {
            params.set('secretaria', filtro.secretaria);
        }

        if (filtro.ano) {
            params.set('ano', filtro.ano);
        }

        if (filtro.status) {
            params.set('status', filtro.status);
        }

        return this.http.get(`${this.url}`, { search: params })
            .toPromise()
            .then(response => {
                return response.json();
            })
    }

    listarTodos(): Promise<any> {
        return this.http.get(this.url)
            .toPromise()
            .then(response => response.json().content);
    }

    excluir(codigo: number): Promise<void> {
        return this.http.delete(`${this.url}/${codigo}`)
            .toPromise()
            .then(() => null);
    }

    adicionar(entidade: PeriodoLetivo): Promise<PeriodoLetivo> {
        return this.http.post(this.url, JSON.stringify(entidade))
            .toPromise()
            .then(response => response.json());
    }

    atualizar(entidade: PeriodoLetivo): Promise<PeriodoLetivo> {

        return this.http.put(`${this.url}/${entidade.id}`,
            JSON.stringify(entidade))
            .toPromise()
            .then(response => response.json() as PeriodoLetivo);
    }

    buscarPorCodigo(codigo: number): Promise<PeriodoLetivo> {
        return this.http.get(`${this.url}/${codigo}`)
            .toPromise()
            .then(response => {
                const entidade = response.json() as PeriodoLetivo;
                return entidade;
            });
    }

}
