import { PlanoTrabalhoHasProponente } from "../../model";


export class RecursoFinanceiro {
  id: number;
  objeto;
  valor;
  natureza;
  situacao;
  favorecido;
  numeroEmpenho;
  dataEmpenho;
  dataLiquidado;
  documentoLiquidado;
  dataDocumentoLiquidado;
  numeroDocumentoLiquidado;
  dataPagamento;
  nfPagamento;
  tipoDocumentoPagamento;
  numeroDocumentoPagamento;
  planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente

}