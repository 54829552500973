import { SerieEscolar } from "./escolar/serieEscolar.model";
import { TurmaHasAluno } from "./escolar/turmaHasAluno.model";

export class AvaliacaoCurso {
	id;
	nome;
	descricao;
	notaParaAprovacao;
	situacao;
	curso = new SerieEscolar();
	questaoAvaliacao = []
}

export class QuestaoAvaliacao {
	id;
	nome;
	peso;
	avaliacaoCurso = new AvaliacaoCurso();
	opcaoAvaliacao = []
}
export class QuestaoAvaliacaoSalvar {
	id;
	nome;
	peso;
	avaliacaoCurso = new AvaliacaoCurso();
}
export class OpcaoQuestao {
	id;
	nome;
	correta;
	questaoAvaliacao = new QuestaoAvaliacaoSalvar();
}
export class Executada{
	id;
	resultado;
	pontuacao;
	dataLog;
	resposta = []
	turmaHasAluno = new TurmaHasAluno;
	avaliacaoCurso = new AvaliacaoCurso;
}
export class Resposta{
	id;
	executada = new Executada;
	opcaoQuestao = new OpcaoQuestao;
}
export class ExecutadaDTO{
	executada = new Executada
	listaOpcoes = []
}