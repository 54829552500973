import { Injectable } from '@angular/core';

import * as moment from 'moment';

@Injectable()
export class ModeloSistemaService {

  constructor() { }

  retornaStatusRoteiro(dataIni, dataFinal): Promise<any> {
    let retorno
    
    let ini = moment(dataIni).format('L')
    let fim = moment(dataFinal).format('L')
    let atual = moment(new Date()).format('L')

    if( atual >= ini && atual <= fim ){
      retorno = 'durante'
    }else if(atual < ini){
      retorno = 'antes'
    }else if(atual > fim){
      retorno = 'depois'
    }else{
      retorno = 'sempre'
    }

    return retorno
  }

}
