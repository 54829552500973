export class ModelLicitacao{
    id; //ID da LIcitacaoPreojeto
    descricaoObjeto
  
    dataCriacao
    dataHoraAbertura
    dataUltimaAlteracao
  
    numProcessoTce
    numProcessoAdm
    tipoLicitacao
    modalidade
    regimeExecucao
    status
    itens = []
  }