import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';
import { MovimentacaoRequisito, Usuario } from '../../model';

export class MovimentacaoRequisitoFiltro {
  logMovimentacao: string;
  descricao: string;
  questaoRequisitoProponente: string;
  pagina = 0;
  itensPorPagina = 100;
}

@Injectable()
export class MovimentacaoRequisitoService {

  movimentacaoRequisitoUrl: string;
  usuarioAdmin = new Usuario();

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.movimentacaoRequisitoUrl = `${environment.apiUrl}/movimentacao-requisito`;
  }

  pesquisar(filtro: MovimentacaoRequisitoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.logMovimentacao) {
      params.set('logMovimentacao', filtro.logMovimentacao);
    }
    if (filtro.questaoRequisitoProponente) {
      params.set('questaoRequisitoProponente', filtro.questaoRequisitoProponente);
    }

    return this.http.get(`${this.movimentacaoRequisitoUrl}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        ////console.log(resultado)
        return resultado;

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.movimentacaoRequisitoUrl)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.movimentacaoRequisitoUrl}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: MovimentacaoRequisito): Promise<MovimentacaoRequisito> {
    return this.http.post(this.movimentacaoRequisitoUrl, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: MovimentacaoRequisito): Promise<MovimentacaoRequisito> {
    return this.http.put(`${this.movimentacaoRequisitoUrl}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as MovimentacaoRequisito);
  }

  buscarPorCodigo(codigo: number): Promise<MovimentacaoRequisito> {
    return this.http.get(`${this.movimentacaoRequisitoUrl}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as MovimentacaoRequisito;
        return entidade;
      });
  }

}
