import { Http, URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';
import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../seguranca/auth.service';
import { SerieEscolar } from '../../../model/escolar/serieEscolar.model';

export class SerieEscolarFiltro {
    identificacao
    entidadeConcedente
    tipoAvaliacao
    tipo

    pagina = 0;
    itensPorPagina = 20;
}

@Injectable()
export class SerieEscolarService {

    url: string;


    constructor(
        private http: AuthHttp,
        private httpPub: Http,
        public auth: AuthService,
    ) {
        this.url = `${environment.apiUrl}/serie-escolar`;
    }

    pesquisar(filtro: SerieEscolarFiltro): Promise<any> {
        const params = new URLSearchParams();

        params.set('page', filtro.pagina.toString());
        params.set('size', filtro.itensPorPagina.toString());

        if (filtro.identificacao) {
            params.set('identificacao', filtro.identificacao);
        }

        if (filtro.entidadeConcedente) {
            params.set('entidadeConcedente', filtro.entidadeConcedente);
        }

        if (filtro.tipoAvaliacao) {
            params.set('tipoAvaliacao', filtro.tipoAvaliacao);
        }

        if (filtro.tipo) {
            params.set('tipo', filtro.tipo);
        }

        return this.http.get(`${this.url}`, { search: params })
            .toPromise()
            .then(response => {
                return response.json();
            })
    }

    pesquisaPublica(filtro: SerieEscolarFiltro): Promise<any> {
        const params = new URLSearchParams();

        params.set('page', filtro.pagina.toString());
        params.set('size', filtro.itensPorPagina.toString());

        if (filtro.identificacao) {
            params.set('identificacao', filtro.identificacao);
        }

        if (filtro.entidadeConcedente) {
            params.set('entidadeConcedente', filtro.entidadeConcedente);
        }

        return this.httpPub.get(`${this.url}/pesquisa-publica`, { search: params })
            .toPromise()
            .then(response => {
                return response.json();
            })
    }

    listarTodos(): Promise<any> {
        return this.http.get(this.url)
            .toPromise()
            .then(response => response.json().content);
    }

    excluir(codigo: number): Promise<void> {
        return this.http.delete(`${this.url}/${codigo}`)
            .toPromise()
            .then(() => null);
    }

    adicionar(entidade: SerieEscolar): Promise<SerieEscolar> {
        return this.http.post(this.url, JSON.stringify(entidade))
            .toPromise()
            .then(response => response.json());
    }

    atualizar(entidade: SerieEscolar): Promise<SerieEscolar> {

        return this.http.put(`${this.url}/${entidade.id}`,
            JSON.stringify(entidade))
            .toPromise()
            .then(response => response.json() as SerieEscolar);
    }

    buscarPorCodigo(codigo: number): Promise<SerieEscolar> {
        return this.http.get(`${this.url}/${codigo}`)
            .toPromise()
            .then(response => {
                const entidade = response.json() as SerieEscolar;
                return entidade;
            });
    }

    countCursos(): Promise<any> {
        return this.http
          .get(`${this.url}/count`)
          .toPromise()
          .then(response => response.json());
      }

}
